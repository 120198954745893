<template>
  <div>
    <el-row :gutter="24">
      <el-col :lg="8">
        <el-card class="box-card" shadow="never" body-style="padding:0">
          <div
            slot="header"
            class="clearfix flex"
            style="display: flex;justify-content: space-between;align-items: center;"
          >
            <span class="line-left">待办列表</span>
            <button class="todo-btn" @click="addClick">添加</button>
          </div>
          <div style="flex: 1; background: #ffffff; height: 284px">
            <el-table
              :data="backlogList"
              style="width: 100%"
              max-height="285"
              :border="false"
              :show-header="false"
            >
              <!-- <el-table-column fixed prop="eventTime" label="日期" width="120">
              </el-table-column> -->
              <el-table-column prop="name" label="事情">
                <template slot-scope="scope">
                  <h4>{{ scope.row.name }}</h4>
                  <p style="font-size: 12px;color: #666;">{{ scope.row.eventTime }}</p>
                  <p style="font-size: 12px;color: #666;">{{ scope.row.content }}</p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="40">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="delClick(scope.row)"
                    type="text"
                    size="small"
                    icon="el-icon-delete"
                  >
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <el-col :lg="8">
        <el-card class="box-card" shadow="never" body-style="padding:0">
          <div slot="header" class="clearfix">
            <span class="line-left">待办事项</span>
          </div>
          <div class="d-flex">
            <div class="left-view d-flex flex-column justify-content-between">
              <div>
                <h3>今日需跟进{{ noFollowCount }}人</h3>
                <span>已经7天未跟进客户了哦</span>
              </div>
              <el-button @click="$router.push({ path: '/customerManagement' })"
                >去跟进</el-button
              >
            </div>
            <div class="right-view d-flex flex-column">
              <el-row :gutter="24">
                <el-col :lg="24">
                  <div class="d-flex first-view flex-column">
                    <div class="d-flex">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-smile"></use>
                      </svg>
                      <div class="d-flex flex-column text-view">
                        <span>今日已跟进</span>
                        <span>客户{{ dealFollowCount }}人</span>
                      </div>
                    </div>
                    <div>
                      <el-progress
                        :percentage="has_follow_today_percent"
                        :format="format"
                        color="#41DDA1"
                      ></el-progress>
                    </div>
                  </div>
                </el-col>
                <el-col :lg="24" style="margin-top: 44px">
                  <div class="d-flex first-view flex-column">
                    <div class="d-flex">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-frown"></use>
                      </svg>
                      <div class="d-flex flex-column text-view">
                        <span>今日未跟进</span>
                        <span>客户{{ no_follow_today }}人</span>
                      </div>
                    </div>
                    <div>
                      <el-progress
                        :percentage="no_follow_today_percent"
                        :format="format"
                        color="#6772E5"
                      ></el-progress>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :lg="8">
        <el-card class="box-card" shadow="never" body-style="padding:0">
          <div slot="header" class="clearfix">
            <span class="line-left">客户兴趣</span>
            <!-- <div class="clearfix-right" style="float: right">
              <el-select
                v-model="value"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option
                  v-for="(item, index) in ['30', '7', '1']"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div style="flex: 1; background: #ffffff; height: 284px">
            <div id="roomTypeCheckIn"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="添加待办事项"
      :visible.sync="dialogVisible"
      width="40%"
      :append-to-body="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="60px"
        class="demo-ruleForm"
      >
        <el-form-item label="日期" prop="eventTime">
          <el-date-picker
            type="datetime"
            placeholder=""
            v-model="ruleForm.eventTime"
            :value-format="'yyyy-MM-dd HH:mm:ss'"
            style="width: 100%;"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" v-model="ruleForm.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import { saveBackLog, delBackLog, getBackLogList } from "@/api/myStatistics";
export default {
  name: "backlog",
  data() {
    return {
      value: "",
      noFollowCount: 0,
      dealFollowCount: 0,
      has_follow_today_percent: 0,
      no_follow_today: 0,
      no_follow_today_percent: 0,
      chartData: [
        { item: "对我感兴趣", value: 0, percent: 0, seeType: 1 },
        { item: "喜欢产品", value: 0, percent: 0, seeType: 2 },
        { item: "看重公司", value: 0, percent: 0, seeType: 3 },
        { item: "关注动态", value: 0, percent: 0, seeType: 4 },
        { item: "预约", value: 0, percent: 0, seeType: 5 },
        { item: "媒体", value: 0, percent: 0, seeType: 6 },
      ],
      dialogVisible: false,
      backlogList: [],
      ruleForm: {
        eventTime: "",
        name: "",
        content: "",
      },
      rules: {
        eventTime: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        name: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [
          { required: true, message: "请输入具体内容", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    state: {
      type: Object,
      default: () => {},
    },
    restChart: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    state(val) {
      const { noFollowCount, dealFollowCount, ownCount } = val;
      this.ownCount = ownCount;
      this.noFollowCount = noFollowCount;
      this.dealFollowCount = dealFollowCount;
      this.has_follow_today_percent = ownCount
        ? (dealFollowCount / ownCount).toFixed(2) * 100
        : 0;
      this.no_follow_today =
        ownCount - dealFollowCount < 0 ? 0 : ownCount - dealFollowCount;
      this.no_follow_today_percent = ownCount
        ? (this.no_follow_today / ownCount).toFixed(2) * 100
        : 0;
    },
    restChart(val) {
      let Arr = JSON.parse(JSON.stringify(val));

      let counet = Arr.reduce((num, item) => {
        return num + item.num;
      }, 0);
      console.log(counet);
      Arr.forEach((item) => {
        this.chartData.forEach((row) => {
          if (item.seeType == row.seeType) {
            console.log((item.num / counet).toFixed(2));
            row.value = item.num;
            row.percent = +(item.num / counet).toFixed(2);
          }
        });
      });

      this.initComponent();
    },
  },
  mounted() {
    this.initBackLog();
  },
  methods: {
    addClick() {
      this.dialogVisible = true;
    },
    async delClick(item) {
      var resp = await delBackLog({ logId: item.id });
      if (resp.code == 200) {
        this.$message.success("成功");
        this.initBackLog();
      }
    },
    async initBackLog() {
      var resp = await getBackLogList({ pageSize: 50, pageNum: 1 });
      console.log("resp", resp);
      if (resp.code == 200) {
        this.backlogList = resp.data;
      }
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.submitForm();
        } else {
          return false;
        }
      });
    },
    async submitForm() {
      var resp = await saveBackLog(this.ruleForm);
      if (resp.code == 200) {
        this.$message.success("成功");
        this.dialogVisible = false;
        this.initBackLog();
      }
    },
    format(percentage) {
      return " ";
    },
    initComponent() {
      const chart = new Chart({
        container: "roomTypeCheckIn",
        autoFit: true,
        height: 280,
      });
      chart.data(this.chartData);
      console.log(this.chartData);
      chart.coordinate("theta", {
        radius: 0.8,
        innerRadius: 0.8,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
      });
      chart.annotation().text({
        position: ["50%", "50%"],
        content: "兴趣",
        style: {
          fontSize: 14,
          fill: "#8c8c8c",
          textAlign: "center",
        },
        offsetY: 0,
      });
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color("item", [
          "#ff5c6c",
          "#7460ee",
          "#24d2b5",
          "#20aee3",
          "#566C84",
          "#FFA133",
        ])
        .tooltip("item*percent", (item, percent) => {
          percent = (percent * 100).toFixed(2) + "%";
          return {
            name: item,
            value: percent,
          };
        });
      chart.render();
    },
  },
};
</script>

<style lang="scss" scoped>
.todo-btn {
  border: none;
  background: transparent;
  font-size: 12px;
  &:hover {
    color: #0d6dff;
    cursor: pointer;
  }
}
.left-view {
  width: 224px;
  height: 284px;
  background: #ffffff;
  border-radius: 0px 0px 0px 2px;
  border-right: 1px solid #dbe5fe;
  padding: 50px 24px;
  h3 {
    font-size: 24px;
    font-family: PingFang SC;
    color: #454a4c;
    margin-bottom: 8px;
  }
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #c0cbcc;
  }
  .el-button {
    width: 126px;
    height: 40px;
    background: #0d6dff;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    &:active {
      border-color: #0d6dff;
    }
  }
}
.right-view {
  padding: 50px 24px;
  flex: 1;
  .first-view {
    .icon {
      height: 50px;
      width: 50px;
      color: #c0cbcc;
    }
    .text-view {
      margin-left: 10px;
      span {
        &:nth-of-type(1) {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #454a4c;
        }
        &:nth-of-type(2) {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c0cbcc;
        }
      }
    }
  }
}
.font-12 {
  font-size: 12px;
}

html body .m-t-30 {
  margin-top: 30px;
}
.text-center {
  text-align: center !important;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
ul.list-inline li {
  display: inline-block;
  padding: 0 8px;
}
.bgdanger {
  background: #ff5c6c !important;
}
.bgpurple {
  background: #7460ee;
}
.bgsuccess {
  background: #24d2b5 !important;
}
.bginfo {
  background: #20aee3 !important;
}
</style>
