<template>
  <div class="dataOverview-index">
    <!-- <el-card class="box-card" shadow="never" body-style="padding-bottom:0">
      <div slot="header" class="clearfix">
        <span class="line-left">数据概览</span>
        <div class="clearfix-right" style="float: right">
          <div class="d-flex right-item align-items-center">
            <span v-for="(item, index) in timeDate" :key="index">
              {{ item }}
            </span>
            <el-date-picker
              v-model="value"
              style="margin-left: 8px"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="text item">
        <el-row :gutter="24" style="margin: 0 -6px !important">
          <el-col
            style="padding: 0 8px !important"
            :lg="8"
            v-for="(item, index) in dataView"
            :key="index"
          >
            <div class="card-content bg d-flex align-items-center">
              <div
                class="icon-div d-flex align-items-center justify-content-center"
              >
                <svg
                  class="icon"
                  aria-hidden="true"
                  v-if="item.source == 'antd'"
                >
                  <use :xlink:href="item.icon"></use>
                </svg>
                <i :class="[item.icon, 'icon']" v-else></i>
              </div>
              <div class="card-right d-flex flex-column">
                <span> {{ item.text }} </span>
                <div class="d-flex">
                  <h3>{{ item.num }}</h3>
                  <p>今日新增 +3</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card> -->
    <div>
      <div class="card-grid">
        <div
          v-for="(item, index) in gridData"
          :key="index"
          class="d-flex align-items-center posre"
          :class="{'dropDown': item.dropDown}"
        >
          <div
            class="icon-div d-flex align-items-center justify-content-center"
            :style="[{ border: item.border, color: item.color }]"
          >
            <svg class="icon" aria-hidden="true" v-if="item.source == 'antd'">
              <use :xlink:href="item.icon"></use>
            </svg>
            <i :class="[item.icon]" v-else></i>
          </div>
          <div class="grid-text d-flex flex-column">
            <span>{{ item.num }}</span>
            <span> {{ item.text }} </span>
          </div>
          <div v-if="item.dropDown && state.newCustomerList" class="posab dropDownList">
            <div class="triangle-up">
              
            </div>
            <div class="text-align d-flex flex-column today-entry">
              <p class="fs10 cblue mb10">今日录入</p>
              <div class="bbule1 br5 flex1" style="overflow-y: auto;">
                <div v-for="(item,index) in state.newCustomerList" 
                     :key="index"
                     :class="{'bbbule1': (index + 1) -state.newCustomerList.length !== 0}"
                     class="d-flex flex-a-b-c borderbox fs7 cA2" style="padding:5px">
                  <p class="overview-name">{{item.name}}</p>
                  <p>{{item.phone || ''}}</p>
                  <p>{{item.createTime}}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "overview",
  data() {
    return {
      timeDate: ["累计", "今日", "近7天"],
      value: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      dataView: [
        {
          icon: "#icon-eye-fill",
          text: "累计访问人数",
          num: 2833,
          source: "antd",
        },
        {
          icon: "el-icon-user-solid",
          text: "累计客户",
          num: 933,
        },
        {
          icon: "#icon-time-circle-fill",
          text: "累计访问人数",
          num: 2833,
          source: "antd",
        },
      ],
      gridData: [
        {
          icon: "#icon-team",
          num: 0,
          text: "总客户",
          border: "2px solid rgba(254, 117, 99, 0.4)",
          color: "#FE7563",
          source: "antd",
        },
        {
          icon: "#icon-adduser",
          num: 0,
          text: "今日录入",
          border: "2px solid rgba(252, 173, 61, 0.4)",
          color: "#FCAD3D",
          source: "antd",
          dropDown: true
        },
        {
          icon: "#icon-filedone",
          num: 0,
          text: "成交客户",
          border: "2px solid rgba(65, 221, 161, 0.4)",
          color: "#41DDA1",
          source: "antd",
        },
        {
          icon: "#icon-solution",
          num: 0,
          text: "我的客户",
          border: "2px solid rgba(68, 132, 253, 0.4)",
          color: "#4484FD",
          source: "antd",
        },
        {
          icon: "el-icon-pie-chart",
          num: "0%",
          border: "2px solid rgba(103, 114, 229, 0.4)",
          color: "#6772E5",
          text: "成交率",
        },
      ],
    };
  },
  props: {
    state: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    state(val) {
      console.log(val)
      const { customerCount, dealCount, ownCount, newCount } = val;
      this.gridData[0].num = customerCount;
      this.gridData[1].num = newCount;
      this.gridData[2].num = dealCount;
      this.gridData[3].num = ownCount;
      this.gridData[4].num = customerCount
        ? ((dealCount / customerCount) * 100).toFixed(2) + "%"
        : "0%";
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-card {
  border: 0px;
}
@media screen and (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media screen and (max-width: 700px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media screen and (max-width: 700px) {
  .clearfix-right {
    display: none;
  }
}
.clearfix-right {
  .right-item {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #737f80;
    span {
      margin-right: 24px;
      cursor: pointer;
    }
  }
}
.bg {
  height: 120px;
  background: #DBE5FE;
  border-radius: 2px;
  margin-bottom: 20px;
}
.card-content {
  padding: 0 24px;
  .icon-div {
    width: 72px;
    height: 72px;
    background: #DBE5FE;
    border-radius: 24px;
    .icon {
      color: #0D6DFF;
      font-size: 35px;
    }
  }
  .card-right {
    margin-left: 16px;
    & > span {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #737f80;
    }
    h3 {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #454a4c;
      margin-top: 8px;
    }
    p {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0D6DFF;
      margin-top: 9px;
      margin-left: 8px;
    }
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 16px;
  & > div {
    height: 104px;
    background: #ffffff;
    margin-bottom: 16px;
    padding: 0 24px;
    .icon-div {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      padding: 1px;
      .icon {
        width: 100%;
        height: 100%;
      }
      i {
        font-size: 23px;
      }
    }
    .grid-text {
      margin-left: 11px;
      span {
        &:nth-of-type(1) {
          font-size: 24px;
          font-family: PingFang SC;
          color: #454a4c;
        }
        &:nth-of-type(2) {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #737f80;
        }
      }
    }
  }
}
.dropDown{
  position: relative;
}
.dropDown:hover .dropDownList{
  display: block;
  transition: all 0.4s;
}
.dropDownList{ 
  display: none;
  top: 120px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  background: #ffffff;
  padding:10px 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all 0.4s;
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    position: absolute;
    top: -10px;
}
.today-entry{
  max-height: 400px;
}
.overview-name{
  display: block;
  width: 80px;
  white-space: nowrap;
  text-overflow:ellipsis;
  overflow: hidden;
  text-align: left;
}
</style>